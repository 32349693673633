import React, { FC, useState } from "react";
import Styles from "../styles/ToggleButton.module.scss"
import clsx from "clsx"

type Props = {
    onChange: ( value: boolean ) => void
    value?: boolean
}

export const ToggleButton: FC<Props> = ( { value, onChange } ) => {

    return (
        <div
            className={ clsx( Styles.switch, value && Styles.on ) }
            onClick={ () => onChange( !value ) }>
        </div>
    )
}
