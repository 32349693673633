import React, { useState, useContext } from 'react'
import client from '../apis/client'
import { ToggleButton } from '../components/ToggleButton'
import { ToastContext } from '../context/ToastContext'
import Styles from "../styles/settings.module.scss"
import { useExecAfterLoggedIn } from './useExecAfterLoggedIn'

type ConfigListKeyType = "open_profile"

type ConfigType = {
    name: ConfigListKeyType
    label: string
    value: boolean
}

const InitialGeneralConfigList: ConfigType[] = [
    { name: "open_profile", label: "自分のコメントの名前をチーム外にも表示する(自分のチームの一般公開の投稿のみ)", value: true },
]
type returnType = {
    renderConfigList: () => React.ReactNode,
}

export const useUserConfig = (): returnType => {

    const [ configList, setConfigList ] = useState<ConfigType[]>( InitialGeneralConfigList );
    const { addToast } = useContext( ToastContext )

    const loadGeneralConfigList = ( fetchedData: { [ key in string ]: boolean } ) => {
        const newConfigList = configList.map( config => (
            {
                name: config.name,
                label: config.label,
                value: fetchedData[ config.name ]
            }
        ) )
        setConfigList( newConfigList )
    }

    const loadConfigList = async () => {
        try {
            const response = await client.get( `/api/user_config/` )
            const data = response.data
            loadGeneralConfigList( data )
        }
        catch ( error ) {
            console.warn( error.response.data )
        }
    }
    useExecAfterLoggedIn( { func: loadConfigList } )

    const onToggle = ( value: boolean, name: ConfigListKeyType ) => {
        const prevConfigList = configList
        const newConfigList = configList.map( config =>
            config.name === name ?
                { ...config, value } :
                config
        )
        setConfigList( newConfigList )
        saveConfig( prevConfigList, newConfigList )
    }

    const saveConfig = async ( prevConfigList: ConfigType[], newConfigList: ConfigType[] ) => {
        const data = new FormData()
        newConfigList.forEach( config =>
            data.append( config.name, config.value as any )
        )
        try {
            await client.patch( `/api/user_config/`, data )
            addToast( {
                text: "設定を変更しました",
                type: "success",
                position: "top"
            } )

        }
        catch ( error ) {
            setConfigList( prevConfigList )
            addToast( {
                text: "設定の変更に失敗しました",
                type: "error",
                position: "top"
            } )
            console.warn( error )
        }
    }

    const renderConfigList = () => {
        return configList.map( config =>
            <li className={ Styles.item }>
                <span className={ Styles.label }>{ config.label }</span>
                <span className={ Styles.button_wrapper }>
                    <ToggleButton
                        onChange={ ( value ) => onToggle( value, config.name ) }
                        value={ config.value } />
                </span>
            </li>
        )
    }

    return {
        renderConfigList,
    }
}
