import { useEffect } from 'react'
import { useIsLoggedIn } from './useIsLoggedIn'

type params = {
    func: () => void
}

export const useExecAfterLoggedIn = ( { func }: params ) => {
    const isLoggedIn = useIsLoggedIn()
    useEffect( () => {
        if ( !isLoggedIn ) return
        func()
    }, [ isLoggedIn ] )
}
