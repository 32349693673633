import React, { FC, useContext, useEffect, useState } from 'react'
import Styles from "../styles/settings.module.scss"
import Layout from '../components/Layout'
import { ToggleButton } from '../components/ToggleButton'
import { BACK_BUTTON_STR } from '../constant/const'
import { LocalSettingsKeyType } from '../types/app'
import { ToastContext } from '../context/ToastContext'
import { ToastPosition, ToastType } from '../types/models/Toast'
import { useLocalSettings } from '../hooks/useLocalSettings'
import { useUserConfig } from '../hooks/useUserConfig'

type SettingType = {
    name: LocalSettingsKeyType
    label: string
    value: boolean
}

const InitialLocalSettings = [
    { name: "showUserNameOnTimeline", label: "タイムライン上で投稿者の名前を表示", value: true },
    { name: "isLikeActive", label: "いいね！機能の表示", value: false },
    { name: "isMyTagActive", label: "Myタグ機能の表示", value: false },
    { name: "commentDateTimeVisible", label: "コメントの日時表示", value: false },
    { name: "serialPost", label: "タイムラインに投稿後も投稿フォーム画面のままにする", value: false },
] as SettingType[]

const SettingsPage: FC = () => {

    const savedSettings = useLocalSettings( {} )
    const [ localSettings, setLocalSettings ] = useState( InitialLocalSettings );
    const { addToast } = useContext( ToastContext )
    const { renderConfigList } = useUserConfig()

    useEffect( () => {
        loadLocalSettings()
    }, [ savedSettings ] )

    const headerOption = {
        headerTitle: "設定",
        leftIcon: BACK_BUTTON_STR,
    }

    const loadLocalSettings = () => {
        const newSettings = localSettings.map( settings => (
            { ...settings, value: savedSettings[ settings.name ] }
        ) )
        console.log( newSettings )
        setLocalSettings( newSettings )
    }

    const onToggle = ( value: boolean, name: LocalSettingsKeyType ) => {
        const newSettings = localSettings.map( setting =>
            setting.name === name ?
                { ...setting, value } :
                setting
        )
        setLocalSettings( newSettings )
        newSettings.forEach( async config => {
            console.log( config.name, JSON.stringify( config.value ) )
            localStorage.setItem( config.name, JSON.stringify( config.value ) )
        } )
        addToast( {
            text: "設定を変更しました",
            type: ToastType.SUCCESS,
            position: ToastPosition.TOP
        } )
    }

    return (
        <Layout headerOption={ headerOption }>
            <div className={ Styles.container }>
                <h2 className={ Styles.title }>設定</h2>
                <ul className={ Styles.settings_list }>
                    { localSettings.map( setting =>
                        <li className={ Styles.item }>
                            <span className={ Styles.label }>{ setting.label }</span>
                            <span className={ Styles.button_wrapper }>
                                <ToggleButton
                                    onChange={ ( value ) => onToggle( value, setting.name ) }
                                    value={ setting.value } />
                            </span>
                        </li>
                    ) }
                    { renderConfigList() }
                </ul>
            </div>
        </Layout>
    )
}
export default SettingsPage
